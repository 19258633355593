import { useRef, useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import config from 'data/config.json';
import {
  ALREADY_REGISTERED,
  SET_ZONE,
  START_REGISTER
} from 'context/RegisterProvider/constants';
import { useRegisterStore } from 'context/RegisterProvider/hooks';
import { validateEmail } from 'services/validateEmail';
import Notice from 'UI/atoms/Notice';
import ReCaptcha from 'UI/atoms/ReCaptcha';
import Form from 'UI/molecules/Form';
import CardContent from 'UI/atoms/CardContent';

const Step1Register = () => {
  const { t, i18n } = useTranslation();
  const [validRecaptcha, setValidRecaptcha] = useState(false);
  const [registerState, registerDispatch] = useRegisterStore();
  const [loading, setLoading] = useState(false);
  const [mailIsValid, setMailIsValid] = useState(true);
  const recaptchaRef = useRef();

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(t('form.error.required'))
      .email(t('form.error.email.invalid'))
      .test('is-mail-valid', t('form.error.email.not-corporative'), () => mailIsValid)
  });

  const { register, handleSubmit, trigger, setError, watch, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      email: ''
    }
  });

  const emailField = watch('email');

  useEffect(() => {
    setMailIsValid(true);
  }, [emailField]);

  useEffect(() => {
    window.sessionStorage.removeItem('token');
    const zone = config.zones.find((zone) => zone.locale === i18n.language);
    registerDispatch({ type: SET_ZONE, payload: zone.id });
  }, []);

  useEffect(() => {
    const validateEmailField = async () => {
      await trigger();
    };
    if (!mailIsValid) {
      validateEmailField();
    }
  }, [mailIsValid]);

  const onSubmit = async (data) => {
    setLoading(true);
    const token = recaptchaRef.current.getValue();
    validateEmail({
      zone_id: registerState.zone,
      email: data.email,
      recaptcha: token
    }).then((result) => {
      if (result.token) {
        window.sessionStorage.setItem('token', result.token);
      }
      if (result.isUsed) {
        registerDispatch({ type: ALREADY_REGISTERED, payload: data });
        return;
      }
      if (!result.isValid) {
        setMailIsValid(false);
        return;
      }
      registerDispatch({ type: START_REGISTER, payload: data });
    }).catch(() => {
      setError('apiError', { message: t('api.error') });
    }).finally(() => {
      setLoading(false);
    });
  };

  const onChangeRecaptcha = () => {
    const recaptchaValue = recaptchaRef.current.getValue();
    if (recaptchaValue) {
      setValidRecaptcha(true);
    }
  };

  const onExpiredRecaptcha = () => {
    setValidRecaptcha(false);
  };

  return (
    <CardContent sm={{ span: 10, offset: 1 }}>
      <div>
        <Notice color="blue" arrow>{t('form.step1.notice')}</Notice>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Field>
            <Form.Label htmlFor="form_email">
              {t('form.step1.email.label')}
            </Form.Label>
            <Form.Control>
              <Form.Input
                disabled={loading}
                error={errors.email}
                id="form_email"
                name="email" {...register('email')}
                placeholder={t('form.step1.email.placeholder')} />
              {errors.email && <Form.Error>{errors.email?.message}</Form.Error>}
            </Form.Control>
          </Form.Field>

          <ReCaptcha
            sitekey={process.env.REACT_APP_SITE_KEY}
            onChange={onChangeRecaptcha}
            onExpired={onExpiredRecaptcha}
            ref={recaptchaRef} />

          {errors.apiError &&
            <Notice color="red">{errors.apiError?.message}</Notice>
          }

          <Form.Button disabled={!validRecaptcha || loading}>
            {t('form.step1.button')}
            {loading && <Form.Loader />}
          </Form.Button>
        </Form>
      </div>
    </CardContent>
  );
};

export default Step1Register;
