import initialState from './initialState';
import {
  ALREADY_REGISTERED,
  RESET,
  SET_ZONE,
  START_REGISTER,
  STEP_2,
  RECOVER_PASSWORD_SENT
} from './constants';

const reducer = (state, action) => {
  switch (action.type) {
    case RESET: {
      return initialState;
    }
    case SET_ZONE: {
      return {
        ...state,
        zone: action.payload
      };
    }
    case ALREADY_REGISTERED: {
      return {
        ...initialState,
        zone: state.zone,
        step: ALREADY_REGISTERED,
        registerData: {
          ...state.registerData,
          ...action.payload
        }
      };
    }
    case START_REGISTER: {
      return {
        ...state,
        step: STEP_2,
        registerData: {
          ...state.registerData,
          ...action.payload
        }
      };
    }
    case RECOVER_PASSWORD_SENT: {
      return {
        ...initialState,
        zone: state.zone,
        step: RECOVER_PASSWORD_SENT
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export default reducer;
