import styles from './styles.module.scss';
import { PropTypes } from 'prop-types';
import { Col } from 'react-bootstrap';

const BigIcon = (props) => {
  const { src, ...other } = props;
  return (
    <Col className={styles.wrapper}>
      <img src={src} {...other} className={styles.logo} />
    </Col>
  );
};

BigIcon.propTypes = {
  src: PropTypes.string
};

export default BigIcon;
