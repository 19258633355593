import styles from './styles.module.scss';
import { PropTypes } from 'prop-types';

const Control = ({ children }) => (
  <div className={styles.control}>{children}</div>
);

Control.propTypes = {
  children: PropTypes.node
};

export default Control;
