import i18n from 'i18next';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(resourcesToBackend((language, namespace, callback) => {
    import(`./locales/${language}/${namespace}.json`)
      .then((resources) => {
        callback(null, resources);
      })
      .catch((error) => {
        callback(error, null);
      });
  }))
  .use(initReactI18next)
  .init({
    lng: 'es',
    fallbackLng: 'es',
    debug: false,
    supportedLngs: ['co', 'ec', 'es', 'fr', 'pe', 'pt'],
    // have a common namespace used around the full app
    ns: ['app'],
    defaultNS: 'app',

    returnObjects: true,

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ','
    },

    react: {
      useSuspense: true
    }
  });

export default i18n;
