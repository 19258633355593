import { PropTypes } from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import logo from 'assets/img/logo.svg';

import styles from './styles.module.scss';

const Card = ({
  children
}) => {
  const { t } = useTranslation();

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col sm={12} md={10} lg={8} xl={7} xxl={6}>
          <div className={styles.card}>
            <Container fluid>
              <Row>
                <div className={styles.header}>
                  <Col sm={{ span: 5, offset: 1 }}>
                    <img src={logo} alt='LMDB logo' className={styles.logo} />
                  </Col>
                  <Col sm={{ span: 5 }}>
                    <p className={styles.title}>{t('register.title')}</p>
                  </Col>
                </div>
              </Row>
            </Container>
            {children}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

Card.propTypes = {
  children: PropTypes.any
};

export default Card;
