import { PropTypes } from 'prop-types';

import Autocomplete from 'UI/atoms/Autocomplete';
import Button from 'UI/atoms/Button';
import Checkbox from 'UI/atoms/Checkbox';
import Control from 'UI/atoms/Control';
import Error from 'UI/atoms/Error';
import Field from 'UI/atoms/Field';
import Input from 'UI/atoms/Input';
import Label from 'UI/atoms/Label';
import Legend from 'UI/atoms/Legend';
import Loader from 'UI/atoms/Loader';

import styles from './styles.module.scss';

const Form = (props) => (
  <form className={styles.form} {...props}>
    {props.children}
  </form>
);

Form.Autocomplete = Autocomplete;

Form.Button = Button;

Form.Checkbox = Checkbox;

Form.Control = Control;

Form.Error = Error;

Form.Field = Field;

Form.Input = Input;

Form.Label = Label;

Form.Legend = Legend;

Form.Loader = Loader;

Form.propTypes = {
  children: PropTypes.node
};

export default Form;
