import { useTranslation } from 'react-i18next';
import { Container, Row, Col } from 'react-bootstrap';

import styles from './styles.module.scss';

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Row className="justify-content-md-center">
        <Col sm={12} md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 1 }} xl={{ span: 7, offset: 1 }} xxl={{ span: 6, offset: 1 }}>
          <div className={styles.card}>
            <Container fluid>
              <Row>
                <Col sm={{ span: 10, offset: 1 }} className={styles.message}>
                  {t('not.found')}
                </Col>
              </Row>
            </Container>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default NotFound;
