import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

const BannerInfo = () => {
  const { t } = useTranslation();

  const bannerInfo = t('banner');

  return (
    <div className={styles.wrapper}>
      <Container>
        <Row className="justify-content-md-center">
          <Col xs={{ span: 10, offset: 1 }} md={{ span: 9, offset: 0 }} lg={7} xl={6} xxl={5}>
            <p className={styles.text}>{bannerInfo.text}</p>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col xs={12} md={10} lg={8} xl={7} xxl={6}>
            <hr className={styles.separator} />
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col xs={{ span: 10, offset: 1 }} md={{ span: 9, offset: 0 }} lg={7} xl={6} xxl={5}>
            <p className={styles.moreInfoTitle}>{bannerInfo.title}</p>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col xs={{ span: 10, offset: 1 }} md={{ span: 9, offset: 0 }} lg={7} xl={6} xxl={5}>
            <p className={styles.moreInfoText}>{bannerInfo.subtitle}</p>
          </Col>
        </Row>
        <Row className="justify-content-md-center">
          <Col xs={{ span: 10, offset: 1 }} md={{ span: 9, offset: 0 }} lg={7} xl={6} xxl={5}>
            <p className={styles.moreInfoPhone}><i className={styles.phoneIcon}></i>{bannerInfo.phone}</p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default BannerInfo;
