import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';

import 'assets/styles/index.css';
import 'assets/styles/bootstrap.scss';
import 'assets/styles/fonts.scss';
import RegisterProvider from 'context/RegisterProvider';
import Index from 'routes';
import NotFound from 'routes/not-found';
import Register from 'routes/register';
import Test from 'routes/test';
import Validation from 'routes/validation';
import 'utils/axiosHeaders';

import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <HelmetProvider>
      <RegisterProvider>
        <BrowserRouter>
          <Routes>
            <Route path='/' element={<App />}>
              <Route index element={<Index />} />
              <Route path='/:locale/' element={<Register />} />
              <Route path='/:locale/validation' element={<Validation />} />
              <Route path='/:locale/test' element={<Test />} />
              <Route path='*' element={<NotFound />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </RegisterProvider>
    </HelmetProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
