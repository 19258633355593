import {
  ALREADY_REGISTERED,
  RECOVER_PASSWORD_SENT,
  STEP_1,
  STEP_2
} from 'context/RegisterProvider/constants';
import { useRegisterStore } from 'context/RegisterProvider/hooks';

import BannerInfo from 'components/BannerInfo';
import Card from 'components/Card';
import PasswordSent from 'components/PasswordSent';
import RecoverPassword from 'components/RecoverPassword';
import Step1Register from 'components/Step1Register';
import Step2Register from 'components/Step2Register';

const Register = () => {
  const [registerState] = useRegisterStore();
  return (
    <>
      <Card>
        {registerState.step === STEP_1 && <Step1Register />}
        {registerState.step === STEP_2 && <Step2Register />}
        {registerState.step === ALREADY_REGISTERED && <RecoverPassword />}
        {registerState.step === RECOVER_PASSWORD_SENT && <PasswordSent />}
      </Card>
      <BannerInfo />
    </>);
};

export default Register;
