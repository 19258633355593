import styles from './styles.module.scss';
import ReCaptchaV2 from 'react-google-recaptcha';
import { forwardRef } from 'react';

const ReCaptcha = forwardRef((props, ref) => (
  <div className={styles.recaptcha}>
    <ReCaptchaV2 {...props} ref={ref} />
  </div>
));

ReCaptcha.displayName = 'ReCaptcha';

export default ReCaptcha;
