import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from 'UI/atoms/Button';

import styles from './styles.module.scss';

const Activity = ({ data }) => {
  const { t } = useTranslation();

  const onClick = () => {
    console.log(data);
  };

  return (<>
    <div className={styles.activity}>
      <p className={styles.continue}>{t('activity.continue_inscription')}</p>
      <div className={styles.imageWrapper}>
        {data.featuredImage &&
          <img className={styles.featuredImage} src={data.featuredImage.url} title={data.title} alt={t('activity.alt_image')} />
        }
        {data.speakers &&
          <img className={styles.speaker} src={data.speakers[0].image} title={data.speakers[0].name} alt={t('activity.alt_speaker')} />
        }
      </div>
      <div className={styles.info}>
        <p className={styles.type}>{t(`activity.type.${data.type}`)}</p>
        <p className={styles.title}>{data.title}</p>
        <div className={styles.location}>
          <p className={styles.city}><i className={styles.mapMarker} />{data.city}</p>
          <p className={styles.date}><i className={styles.calendar}></i> 28/03/2017 <i className={styles.grayArrow}></i> 8h</p>
        </div>
        {data.collaborators &&
          <ul className={styles.collaborators}>
            {data.collaborators.map((collaborator) => (
              <li key={collaborator.name}>
                <img src={collaborator.image} className={styles.collaboratorImage} title={collaborator.name} />
              </li>
            ))}
          </ul>
        }
      </div>
    </div>
    <Button onClick={onClick}>
      {t('step3.continue.inscription')}
    </Button>
  </>);
};

Activity.propTypes = {
  data: PropTypes.object
};

export default Activity;
