import { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import check from 'assets/img/icon/ok.png';
import { resetPassword } from 'services/resetPassword';
import { useRegisterStore } from 'context/RegisterProvider/hooks';
import BigIcon from 'UI/atoms/BigIcon';
import CardContent from 'UI/atoms/CardContent';
import Title from 'UI/atoms/Title';
import Notice from 'UI/atoms/Notice';
import Link from 'UI/atoms/Link';
import Form from 'UI/molecules/Form';
import Dialog from 'components/Dialog';
import PasswordSent from 'components/PasswordSent';

const RecoverPassword = () => {
  const { t } = useTranslation();
  const [registerState] = useRegisterStore();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const { handleSubmit, setError, reset, formState: { errors } } = useForm();

  const closeModal = () => {
    setShowModal(false);
  };

  const onSubmit = async (data) => {
    setLoading(true);
    resetPassword({
      zone_id: registerState.zone,
      email: registerState.registerData.email
    }).then((result) => {
      if (!result.success) {
        setError('apiError', { message: t(`form.recoverPassword.${result.error}`) });
        return;
      }
      setShowModal(true);
      reset();
    }).catch(() => {
      setError('apiError', { message: t('api.error') });
    }).finally(() => {
      setLoading(false);
    });
  };

  return (
    <>
      <CardContent sm={{ span: 10, offset: 1 }}>
        <BigIcon src={check}></BigIcon>
        <Title>{t('already.registered.title')}</Title>
        <Notice color="blue">
          <p>{t('already.registered.text')}</p>
          <Link arrow='right' href={t('already.registered.link')}>
            <strong>{t('already.registered.linkText')}</strong>
          </Link>
        </Notice>
      </CardContent>
      <CardContent color='gray' sm={{ span: 10, offset: 1 }}>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col>
              <Form.Legend>{t('form.recoverPassword.legend')}</Form.Legend>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Field>
                <Form.Label htmlFor="form_email">{t('form.help.email.label')}</Form.Label>
                <Form.Control>
                  <Form.Input disabled id="form_email" name="email"
                    defaultValue={registerState.registerData.email}
                    placeholder={t('form.help.email.placeholder')} />
                  {errors.email && <Form.Error>{errors.email?.message}</Form.Error>}
                </Form.Control>
              </Form.Field>
            </Col>
          </Row>

          {errors.apiError &&
            <Notice color="red">{errors.apiError?.message}</Notice>
          }

          <Form.Button disabled={loading}>
            {t('form.help.button')}
            {loading && <Form.Loader />}
          </Form.Button>
        </Form>

        {showModal && <Dialog onClose={closeModal} body={() => (<PasswordSent />)}></Dialog>}
      </CardContent>
    </>
  );
};

export default RecoverPassword;
