import { PropTypes } from 'prop-types';
import styles from './styles.module.scss';

const Subtitle = ({ children }) => (<p className={styles.subtitle}>
  {children}
</p>);

Subtitle.propTypes = {
  children: PropTypes.node
};

export default Subtitle;
