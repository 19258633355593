
import { PropTypes } from 'prop-types';
import { useState } from 'react';
import { Modal } from 'react-bootstrap';

import styles from './styles.module.scss';

const Dialog = ({
  header,
  body,
  footer,
  onClose
}) => {
  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  return <>
    <Modal show={show} centered onHide={handleClose} dialogClassName={styles.dialog}>
      <Modal.Header closeButton className={styles.header}>
        {header && header()}
      </Modal.Header>
      {body &&
        <Modal.Body className={styles.body}>
          {body()}
        </Modal.Body>
      }
      {footer &&
        <Modal.Footer>
          {footer()}
        </Modal.Footer>
      }
    </Modal>
  </>;
};

Dialog.propTypes = {
  header: PropTypes.func,
  body: PropTypes.func,
  footer: PropTypes.func,
  onClose: PropTypes.func
};

export default Dialog;
