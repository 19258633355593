import { useTranslation } from 'react-i18next';

import ok from 'assets/img/icon/ok.png';
import BigIcon from 'UI/atoms/BigIcon';
import CardContent from 'UI/atoms/CardContent';
import Title from 'UI/atoms/Title';
import TextBox from 'UI/atoms/TextBox';
import Link from 'UI/atoms/Link';

const RegisterValidation = () => {
  const { t } = useTranslation();

  return (
    <CardContent sm={{ span: 10, offset: 1 }}>
      <BigIcon src={ok} />
      <Title>{t('register_validation.title')}</Title>
      <TextBox color="blue">
        <p>{t('register_validation.text')}</p>
        <Link arrow="down" href={t('register_validation.href')}>
          <strong>{t('register_validation.link')}</strong>
        </Link>
      </TextBox>
    </CardContent>
  );
};

export default RegisterValidation;
