import BannerInfo from 'components/BannerInfo';
import Card from 'components/Card';
import RegisterValidation from 'components/RegisterValidation';

const Validation = () => {
  return (
    <>
      <Card>
        <RegisterValidation />
      </Card>
      <BannerInfo />
    </>
  );
};

export default Validation;
