import AtomsRepository from 'components/AtomsRepository';
import BannerInfo from 'components/BannerInfo';
import Card from 'components/Card';

const Test = () => {
  return (
    <>
      <Card>
        <AtomsRepository />
      </Card>
      <BannerInfo />
    </>);
};

export default Test;
