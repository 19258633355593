/* eslint-disable no-unused-vars */
import { createRef, useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import config from 'data/config.json';
import { useRegisterStore } from 'context/RegisterProvider/hooks';
import Dialog from 'components/Dialog';
import ContactForm from 'components/ContactForm';
import ValidationMailSent from 'components/ValidationMailSent';
import { getProvinces } from 'services/provinces';
import { createUser } from 'services/createUser';
import Notice from 'UI/atoms/Notice';
import CardContent from 'UI/atoms/CardContent';
import Form from 'UI/molecules/Form';
import { validateId } from 'services/validateId';
import { getCompanyDepartments } from 'services/getCompanyDepartments';
import { getWorkPositions } from 'services/getWorkPositions';

const Step2Register = () => {
  const { t } = useTranslation();

  const [registerState] = useRegisterStore();
  const [loading, setLoading] = useState(false);
  const [provinces, setProvinces] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [workPositions, setWorkPositions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [validationSent, setValidationSent] = useState(false);

  const countryIdentifier = config.zones.find((zone) => zone.id === registerState.zone)?.isoCode;

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required(t('form.error.required')),
    surname1: Yup.string()
      .required(t('form.error.required')),
    surname2: Yup.string()
      .required(t('form.error.required')),
    legal_identifier: Yup.string()
      .required(t('form.error.required'))
      .test('is-identifier-valid', t('form.step2.legal_identifier.invalid'), async (value) => {
        if (!value) return true;
        const result = await validateId({ id: value, country_code: countryIdentifier });
        return result.valid;
      }),
    company: Yup.string()
      .required(t('form.error.required')),
    province: Yup.string()
      .required(t('form.error.required')),
    department: Yup.string()
      .test('len', t('form.error.required'), (val) => val.length > 0)
      .required(t('form.error.required')),
    workPosition: Yup.string()
      .test('len', t('form.error.required'), (val) => val.length > 0)
      .required(t('form.error.required')),
    phone: Yup.string()
      .required(t('form.error.required')),
    password: Yup.string()
      .required(t('form.error.required')),
    confirmation_password: Yup.string()
      .required(t('form.error.required'))
      .oneOf([Yup.ref('password'), null], t('form.error.password.match')),
    conditions: Yup.boolean()
      .oneOf([true], t('form.error.required'))
  });

  const { register, handleSubmit, setError, getValues, setValue, watch, formState: { submitCount, errors } } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    resolver: yupResolver(validationSchema),
    defaultValues: {
      email: registerState.registerData.email,
      country_identifier: countryIdentifier,
      zone_id: registerState.zone
    }
  });

  const department = watch('department');
  const workPositionInput = createRef();

  const onSubmit = async (data) => {
    const userData = {
      name: data.name,
      surname1: data.surname1,
      surname2: data.surname2,
      email: data.email,
      password: data.password,
      zone_id: data.zone_id,
      communication_acceptance: data.communication_acceptance,
      legal_identifier: data.legal_identifier,
      country_identifier: data.country_identifier,
      company_name: data.company,
      province: data.province,
      phone: data.phone,
      department: parseInt(data.department, 10),
      work_position: parseInt(data.workPosition, 10)
    };
    setLoading(true);
    createUser(userData).then(() => {
      setValidationSent(true);
    }).catch(() => {
      setError('apiError', { message: t('api.error') });
    }).finally(() => {
      setLoading(false);
    });
  };

  const filterByCallback = (option, search) => (
    option.name.toLowerCase().indexOf(search.text.toLowerCase()) === 0
  );

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    getProvinces(registerState.zone).then((provinces) => {
      setProvinces(provinces);
    });
    getCompanyDepartments(countryIdentifier).then((departments) => {
      setDepartments(departments);
    });
  }, []);

  useEffect(() => {
    workPositionInput.current.clear();
    setValue('workPosition', '');
    if (!department || department.length === 0) {
      return;
    }
    getWorkPositions(department, countryIdentifier).then((workPositions) => {
      setWorkPositions(workPositions);
    });
  }, [department]);

  useEffect(() => {
    setShowModal(Object.keys(errors).length > 0);
    // setShowModal(Object.keys(errors).length > 0 && submitCount === 3);
  }, [errors, submitCount]);

  if (validationSent) {
    return <ValidationMailSent />;
  }

  return (
    <CardContent sm={{ span: 10, offset: 1 }}>
      <div>
        <Notice color="blue" arrow>{t('form.step2.notice')}</Notice>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Form.Input type="hidden" {...register('zone_id')} name="zone_id" />
          <Form.Input type="hidden" {...register('country_identifier')} name="country_identifier" />
          <Form.Field>
            <Form.Label htmlFor="form_name">{t('form.step2.name.label')}</Form.Label>
            <Form.Control>
              <Form.Input error={errors.name} disabled={loading} id="form_name" name="name" {...register('name')}
                placeholder={t('form.step2.name.placeholder')} />
              {errors.name && <Form.Error>{errors.name?.message}</Form.Error>}
            </Form.Control>
          </Form.Field>

          <Row>
            <Col xs={12} sm={6}>
              <Form.Field>
                <Form.Label htmlFor="form_surname1">{t('form.step2.surname1.label')}</Form.Label>
                <Form.Control>
                  <Form.Input error={errors.surname1} disabled={loading} id="form_surname1" name="surname1" {...register('surname1')}
                    placeholder={t('form.step2.surname1.placeholder')} />
                  {errors.surname1 && <Form.Error>{errors.surname1?.message}</Form.Error>}
                </Form.Control>
              </Form.Field>
            </Col>
            <Col xs={12} sm={6}>
              <Form.Field>
                <Form.Label htmlFor="form_surname2">{t('form.step2.surname2.label')}</Form.Label>
                <Form.Control>
                  <Form.Input error={errors.surname2} disabled={loading} id="form_surname2" name="surname2" {...register('surname2')}
                    placeholder={t('form.step2.surname2.placeholder')} />
                  {errors.surname2 && <Form.Error>{errors.surname2?.message}</Form.Error>}
                </Form.Control>
              </Form.Field>
            </Col>
          </Row>

          <Form.Field>
            <Form.Label htmlFor="form_email">{t('form.step2.email.label')}</Form.Label>
            <Form.Control>
              <Form.Input disabled id="form_email" name="email" {...register('email')}
                placeholder={t('form.step2.email.placeholder')} />
            </Form.Control>
          </Form.Field>

          <Row>
            <Col xs={12} sm={6}>
              <Form.Field>
                <Form.Label htmlFor="form_legal_identifier">{t('form.step2.legal_identifier.label')}</Form.Label>
                <Form.Control>
                  <Form.Input error={errors.legal_identifier} disabled={loading} id="form_legal_identifier" name="legal_identifier" {...register('legal_identifier')}
                    placeholder={t('form.step2.legal_identifier.placeholder')} />
                  {errors.legal_identifier && <Form.Error>{errors.legal_identifier?.message}</Form.Error>}
                </Form.Control>
              </Form.Field>
            </Col>
            <Col xs={12} sm={6}>
              <Form.Field>
                <Form.Label htmlFor="form_company">{t('form.step2.company.label')}</Form.Label>
                <Form.Control>
                  <Form.Input error={errors.company} disabled={loading} id="form_company" name="company" {...register('company')}
                    placeholder={t('form.step2.company.placeholder')} />
                  {errors.company && <Form.Error>{errors.company?.message}</Form.Error>}
                </Form.Control>
              </Form.Field>
            </Col>
          </Row>

          <Row>
            <Col xs={12} sm={6}>
              <Form.Field>
                <Form.Label htmlFor="form_department">{t('form.step2.department.label')}</Form.Label>
                <Form.Control>
                  <Form.Input type="hidden" {...register('department')} name="department" />
                  <Form.Autocomplete
                    id="form_department"
                    disabled={loading}
                    error={errors.department}
                    labelKey={(option) => (option.name)}
                    filterBy={filterByCallback}
                    onChange={(selectedOptions) => {
                      if (selectedOptions.length === 0) {
                        setValue('department', '');
                        return;
                      }
                      setValue('department', selectedOptions[0].id);
                    }}
                    renderMenuItemChildren={(option) => (option.name)}
                    placeholder={t('form.step2.department.placeholder')}
                    options={departments}
                  />
                  {errors.department && <Form.Error>{errors.department?.message}</Form.Error>}
                </Form.Control>
              </Form.Field>
            </Col>

            <Col xs={12} sm={6}>
              <Form.Field>
                <Form.Label htmlFor="form_work_position">{t('form.step2.work_position.label')}</Form.Label>
                <Form.Control>
                  <Form.Input type="hidden" {...register('workPosition')} name="work_position" />
                  <Form.Autocomplete
                    id="form_work_position"
                    ref={workPositionInput}
                    disabled={loading || !department || workPositions.length === 0}
                    error={errors.workPosition}
                    labelKey={(option) => (option.name)}
                    filterBy={filterByCallback}
                    onChange={(selectedOptions) => {
                      setValue('workPosition', selectedOptions[0].id);
                    }}
                    renderMenuItemChildren={(option) => (option.name)}
                    placeholder={t('form.step2.work_position.placeholder')}
                    options={workPositions}
                  />
                  {errors.workPosition && <Form.Error>{errors.workPosition?.message}</Form.Error>}
                </Form.Control>
              </Form.Field>
            </Col>
          </Row>

          <Row>
            <Col xs={12} sm={6}>
              <Form.Field>
                <Form.Label htmlFor="form_province">{t('form.step2.province.label')}</Form.Label>
                <Form.Control>
                  <Form.Input type="hidden" {...register('province')} name="province" />
                  <Form.Autocomplete
                    id="form_province"
                    disabled={loading}
                    error={errors.province}
                    labelKey={(option) => (option.name)}
                    filterBy={filterByCallback}
                    onChange={(selectedOptions) => {
                      if (selectedOptions.length === 0) {
                        setValue('province', '');
                        return;
                      }
                      setValue('province', selectedOptions[0].name);
                    }}
                    renderMenuItemChildren={(option) => (
                      <div>{option.name}</div>
                    )}
                    placeholder={t('form.step2.province.placeholder')}
                    options={provinces}
                  />
                  {errors.province && <Form.Error>{errors.province?.message}</Form.Error>}
                </Form.Control>
              </Form.Field>
            </Col>
            <Col xs={12} sm={6}>
              <Form.Field>
                <Form.Label htmlFor="form_phone">{t('form.step2.phone.label')}</Form.Label>
                <Form.Control>
                  <Form.Input error={errors.phone} disabled={loading} id="form_phone" name="phone" {...register('phone')}
                    placeholder={t('form.step2.phone.placeholder')} />
                  {errors.phone && <Form.Error>{errors.phone?.message}</Form.Error>}
                </Form.Control>
              </Form.Field>
            </Col>
          </Row>

          <Row>
            <Col xs={12} sm={6}>
              <Form.Field>
                <Form.Label htmlFor="form_password">{t('form.step2.password.label')}</Form.Label>
                <Form.Control>
                  <Form.Input error={errors.password} disabled={loading} type="password" id="form_password" name="password" {...register('password')}
                    placeholder={t('form.step2.password.placeholder')} />
                  {errors.password && <Form.Error>{errors.password?.message}</Form.Error>}
                </Form.Control>
              </Form.Field>
            </Col>
            <Col xs={12} sm={6}>
              <Form.Field>
                <Form.Label htmlFor="form_confirmation_password">{t('form.step2.confirmation_password.label')}</Form.Label>
                <Form.Control>
                  <Form.Input error={errors.confirmation_password} disabled={loading} type="password" id="form_confirmation_password" name="confirmation_password" {...register('confirmation_password')}
                    placeholder={t('form.step2.confirmation_password.placeholder')} />
                  {errors.confirmation_password && <Form.Error>{errors.confirmation_password?.message}</Form.Error>}
                </Form.Control>
              </Form.Field>
            </Col>
          </Row>

          <Row>
            <Col>
              <Form.Field>
                <Form.Control>
                  <Form.Checkbox error={errors.conditions} htmlFor="form_conditions" disabled={loading} id="form_conditions"
                    name="conditions" {...register('conditions')}>
                    {t('form.step2.conditions.label')}
                  </Form.Checkbox>
                  {errors.conditions && <Form.Error>{errors.conditions?.message}</Form.Error>}
                </Form.Control>
                <Form.Control>
                  <Form.Checkbox htmlFor="form_communication_acceptance" disabled={loading} id="form_communication_acceptance"
                    name="communication_acceptance" {...register('communication_acceptance')}>
                    {t('form.step2.communication_acceptance.label')}
                  </Form.Checkbox>
                </Form.Control>
              </Form.Field>
            </Col>
          </Row>

          {errors.apiError &&
            <Notice color="red">{errors.apiError?.message}</Notice>
          }

          <Form.Button disabled={loading}>
            {t('form.step2.button')}
            {loading && <Form.Loader />}
          </Form.Button>
        </Form>
      </div>
      {showModal > 0 &&
        <Dialog onClose={closeModal} body={() => (<ContactForm formData={getValues()} />)}></Dialog>
      }
    </CardContent>
  );
};

export default Step2Register;
