import { PropTypes } from 'prop-types';
import { forwardRef } from 'react';

import styles from './styles.module.scss';

const Input = forwardRef((props, ref) => {
  const { error, ...others } = props;
  return (<input className={`${styles.input} ${error ? styles.error : ''}`} ref={ref} {...others} />);
});

Input.displayName = 'Input';

Input.propTypes = {
  error: PropTypes.any
};

export default Input;
