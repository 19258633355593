import BannerInfo from 'components/BannerInfo';
import Card from 'components/Card';
import CountrySelector from 'components/CountrySelector';

const Index = () => {
  return (
    <>
      <Card>
        <CountrySelector />
      </Card>
      <BannerInfo />
    </>
  );
};

export default Index;
