import styles from './styles.module.scss';
import { PropTypes } from 'prop-types';

const Error = ({ children }) => (
  <span className={styles.error}>
    {children}
  </span>
);

Error.propTypes = {
  children: PropTypes.node
};

export default Error;
