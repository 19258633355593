import { PropTypes } from 'prop-types';
import styles from './styles.module.scss';

const Link = (props) => {
  const { children, arrow, ...other } = props;
  return (
    <p className={styles.paragraph}>
      <a className={styles.link} {...other}>
        <i className={`${styles.arrow} ${styles[`arrow--${arrow}`]}`}></i>
        {children}
      </a>
    </p>
  );
};

Link.propTypes = {
  arrow: PropTypes.string,
  children: PropTypes.node
};

export default Link;
