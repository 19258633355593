import { useEffect } from 'react';
import { useMatch, Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

import Footer from 'components/Footer';
import i18n from 'translations';
import changeLanguage from 'translations/changeLanguage';

import styles from './App.module.scss';

const lang = i18n.language;

function App () {
  const match = useMatch('/:locale/*');
  const { t } = useTranslation();

  useEffect(() => {
    if (match && lang !== match.params.locale) {
      changeLanguage(match.params.locale);
    }
  }, [match]);

  return (
    <>
      <Helmet>
        <html lang={lang} />
        <title>{t('meta.title')}</title>
      </Helmet>
      <div className={styles.app}>
        <div className={styles.content}>
          {<Outlet />}
        </div>
        <Footer />
      </div>
    </>
  );
}

export default App;
