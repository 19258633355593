import { useTranslation } from 'react-i18next';

import i18n from 'translations';

import styles from './styles.module.scss';

const AddressList = () => {
  const { t } = useTranslation();

  const addresses = t('addresses');

  if (!i18n.exists('addresses') || addresses.length === 0) return '';

  return (
    <div className={styles.list}>
      {addresses.map((item, index) => (
        <div key={index} className={styles.item}>
          <p className={styles.department}>{item.department}</p>
          <p className={styles.data}>{item.address}</p>
          {item.mail &&
            <p className={styles.data}>{item.mail}</p>
          }
          {item.phone &&
            <p className={styles.data}>{item.phone}</p>
          }
        </div>
      ))}
    </div>
  );
};

export default AddressList;
