import { PropTypes } from 'prop-types';
import styles from './styles.module.scss';

const TextBox = (props) => (<div className={`${styles.box} ${styles[`box--${props.color}`]}`}>
  {props.children}
</div>);

TextBox.defaultProps = {
  color: 'transparent'
};

TextBox.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node
};

export default TextBox;
