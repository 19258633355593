import styles from './styles.module.scss';
import { PropTypes } from 'prop-types';

const Button = (props) => {
  const { disabled, children, ...other } = props;
  return (<button type="submit" disabled={disabled} className={styles.button} {...other}>
    {children}
  </button>);
};

Button.defaultProps = {
  disabled: false
};

Button.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.node
};

export default Button;
