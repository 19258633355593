import styles from './styles.module.scss';
import { PropTypes } from 'prop-types';

const Field = ({ children }) => (
  <div className={styles.wrapper}>
    {children}
  </div>
);

Field.propTypes = {
  children: PropTypes.node
};

export default Field;
