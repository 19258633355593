import { PropTypes } from 'prop-types';
import { forwardRef } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';

import styles from './styles.module.scss';

const Autocomplete = forwardRef((props, ref) => {
  const { error, ...others } = props;
  return (
    <Typeahead
      ref={ref}
      className={`${styles.select} ${error ? styles.error : ''}`}
      {...others}
    />
  );
});

Autocomplete.displayName = 'Autocomplete';

Autocomplete.propTypes = {
  error: PropTypes.any
};

export default Autocomplete;
