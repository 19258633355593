import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { RESET } from 'context/RegisterProvider/constants';
import { useRegisterStore } from 'context/RegisterProvider/hooks';
import config from 'data/config.json';
import changeLanguage from 'translations/changeLanguage';
import generatePath from 'translations/generatePath';
import CardContent from 'UI/atoms/CardContent';

import styles from './styles.module.scss';

const CountrySelector = () => {
  const { t } = useTranslation();
  const [, registerDispatch] = useRegisterStore();
  const location = useLocation();

  const setZone = (zone) => {
    changeLanguage(zone.locale);
  };

  useEffect(() => {
    registerDispatch({ type: RESET });
  }, []);

  return (
    <CardContent sm={{ span: 10, offset: 1 }}>
      <h2 className={styles.title}>{t('choose.delegation')}</h2>
      <div className={styles.separator}><span></span></div>
      {config.zones.length > 0 &&
        <ul className={styles.delegations}>
          {config.zones.map((zone) => (
            <li className={styles.delegation} key={zone.locale}>
              <Link to={generatePath(zone.locale, location)} className={styles.link} onClick={() => setZone(zone)}>
                {zone.name}
              </Link>
            </li>
          ))}
        </ul>
      }
    </CardContent>
  );
};

export default CountrySelector;
