import axios from 'axios';

import toCamelCase from 'utils/toCamelCase';

export const getProvinces = async (zone) => {
  const { data } = await axios.get('/provinces', { params: { zone_id: zone } });

  return data.map((province) => ({
    id: province.id,
    name: toCamelCase(province.name.toLowerCase())
  }))
    .sort((a, b) => a.name.localeCompare(b.name));
};
