import { Container, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import logo from 'assets/img/logo.svg';
import AddressList from 'components/AddressList';
import LinksList from 'components/LinksList';
import SocialNetworks from 'components/SocialNetworks';

import styles from './styles.module.scss';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.footer}>
      <Container>
        <Row>
          <Col xs={12} md={12} lg={2} className={styles.logoWrapper}>
            <img src={logo} className={styles.logo} alt="APD logo" />
          </Col>
          <Col xs={12} md={12} lg={10}>
            <AddressList />
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={styles.linksWrapper}>
              <p className={styles.copyright}>{t('reserved.rights')}</p>
              <LinksList />
              <SocialNetworks />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Footer;
