import { STEP_1 } from './constants';

const initialState = {
  step: STEP_1,
  zone: null,
  registerData: {
    email: null,
    token: null,
    name: null,
    surname: null,
    id: null,
    companyName: null,
    province: null,
    phone: null,
    password: null,
    repassword: null,
    communicationsAcceptance: false
  }
};

export default initialState;
