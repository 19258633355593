import { useTranslation } from 'react-i18next';

import envelope from 'assets/img/icon/envelope.png';
import BigIcon from 'UI/atoms/BigIcon';
import CardContent from 'UI/atoms/CardContent';
import Title from 'UI/atoms/Title';
import TextBox from 'UI/atoms/TextBox';

const PasswordSent = () => {
  const { t } = useTranslation();

  return (
    <CardContent sm={{ span: 10, offset: 1 }}>
      <BigIcon src={envelope} />
      <Title>{t('password_sent.title')}</Title>
      <TextBox color="blue">{t('password_sent.text')}</TextBox>
    </CardContent>
  );
};

export default PasswordSent;
