
import { useTranslation } from 'react-i18next';

import i18n from 'translations';

import styles from './styles.module.scss';

const SocialNetworks = () => {
  const { t } = useTranslation();

  const networks = t('networks');

  if (!i18n.exists('networks') || networks.length === 0) return '';

  return (
    <ul className={styles.list}>
      {networks.map((item) => (
        <li className={styles.item} key={item.name}><a href={item.link}><i className={`${styles.icon} ${styles[`icon--${item.name}`]}`} /></a></li>
      ))}
    </ul>
  );
};

export default SocialNetworks;
