import { PropTypes } from 'prop-types';
import styles from './styles.module.scss';
import { Container, Row, Col } from 'react-bootstrap';

const CardContent = (props) => {
  const { color, children, ...other } = props;

  return (
    <div className={`${styles.wrapper} ${styles[`wrapper--${color}`]}`}>
      <Container fluid>
        <Row>
          <Col {...other} className={styles.content}>
            {children}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

CardContent.defaultProps = {
  color: 'white'
};

CardContent.propTypes = {
  color: PropTypes.string,
  children: PropTypes.node
};

export default CardContent;
