import { useTranslation } from 'react-i18next';

import envelope from 'assets/img/icon/envelope.png';
import Activity from 'components/Activity';
import BigIcon from 'UI/atoms/BigIcon';
import CardContent from 'UI/atoms/CardContent';
import Link from 'UI/atoms/Link';
import Subtitle from 'UI/atoms/Subtitle';
import TextBox from 'UI/atoms/TextBox';
import Title from 'UI/atoms/Title';

const AtomsRepository = () => {
  const { t } = useTranslation();

  return (
    <>
      <CardContent sm={{ span: 10, offset: 1 }}>
        <BigIcon src={envelope}></BigIcon>
        <Title>{t('step3.title')}</Title>
        <Subtitle>{t('step3.subtitle')}</Subtitle>
        <TextBox color="gray">
          <p>Vemos que no tienes una empresa asociada.
            Para disfrutar de una mejor experiencia te recomendamos que asocies una empresa a tu perfil.</p>
          <Link href="https://www.google.com" target="_blank" arrow="down">
            <strong>Completa tu perfil</strong>
          </Link>
        </TextBox>
        <TextBox color="yellow">
          <p>Vemos que no tienes una empresa asociada.
            Para disfrutar de una mejor experiencia te recomendamos que asocies una empresa a tu perfil.</p>
          <Link href="/lorem-ipsum.pdf" download arrow="right">
            <strong>Descargar APD Suite</strong>
          </Link>
        </TextBox>
      </CardContent>
      <CardContent color="light-gray" sm={{ span: 10, offset: 1 }}>
        <Activity data={{
          title: 'Club de operaciones',
          type: 'JO',
          typeName: 'Jornada',
          city: 'Santiago de Compostela',
          code: 'JO220485',
          realizationDate: '28/03/2017',
          startDate: '2022-05-30T09:30:00+02:00',
          endDate: '2022-05-30T13:45:00+02:00',
          featuredImage: {
            url: '/mock-assets/img/activity.jpeg'
          },
          speakers: [{
            name: 'speaker_name',
            image: '/mock-assets/img/speaker.jpg'
          }],
          collaborators: [{
            name: 'Improven',
            image: '/mock-assets/img/improven.png'
          }, {
            name: 'Directia',
            image: '/mock-assets/img/directia.png'
          }]
        }}></Activity>
      </CardContent>
      <CardContent color="gray" sm={{ span: 10, offset: 1 }}>
        <TextBox>
          <p>Puedes ver toda la información de nuestras actividades, inscribirte y acceder a tu área privada.</p>
          <Link href="https://www.google.com" target="_blank" arrow="right">
            Ir a la web
          </Link>
        </TextBox>
      </CardContent>
    </>
  );
};

export default AtomsRepository;
