import { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { PropTypes } from 'prop-types';

import HelpRequestSent from 'components/HelpRequestSent';
import CardContent from 'UI/atoms/CardContent';
import Form from 'UI/molecules/Form';
import Notice from 'UI/atoms/Notice';
import Title from 'UI/atoms/Title';
import { requestHelp } from 'services/requestHelp';

const ContactForm = ({ formData }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [emailSent, setEmailSent] = useState(false);

  const validationSchema = Yup.object().shape({
    privacy_acceptance: Yup.boolean()
      .oneOf([true], t('form.error.required')),
    email: Yup.string()
      .required(t('form.error.required'))
      .email(t('form.help.email.invalid'))
  });

  const { register, handleSubmit, setError, reset, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
    defaultValues: {
      email: formData.email,
      privacy_acceptance: false
    }
  });

  const onSubmit = async (data) => {
    setLoading(true);
    const surname = `${formData.surname1} ${formData.surname2}`;
    const helpData = {
      name: (formData.name !== '' ? formData.name : 'No completado'),
      surname: (surname !== ' ' ? surname : 'No completado'),
      email: formData.email,
      zone_id: formData.zone_id,
      company: formData.company,
      phone: formData.phone,
      province: formData.province,
      privacy_acceptance: data.privacy_acceptance
    };

    requestHelp(helpData).then((result) => {
      if (!result.success) {
        setError('apiError', { message: t(`form.contact-form.${result.error}`) });
        return;
      }
      reset();
      setEmailSent(true);
    }).catch(() => {
      setError('apiError', { message: t('api.error') });
    }).finally(() => {
      setLoading(false);
    });
  };

  if (emailSent) {
    return <HelpRequestSent />;
  }

  return <>
    <CardContent sm={{ span: 10, offset: 1 }}>
      <Title>{t('modal.help.title')}</Title>
      <div>
        <Notice color="blue" arrow>{t('modal.help.text')}</Notice>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Row>
            <Col>
              <Form.Field>
                <Form.Label htmlFor="form_email">{t('form.help.email.label')}</Form.Label>
                <Form.Control>
                  <Form.Input disabled={true} id="form_email" name="email" {...register('email')} placeholder={t('form.help.email.placeholder')} />
                  {errors.email && <Form.Error>{errors.email?.message}</Form.Error>}
                </Form.Control>
              </Form.Field>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Field>
                <Form.Control>
                  <Form.Checkbox error={errors.privacy_acceptance} htmlFor="form_privacy_acceptance" disabled={loading} id="form_privacy_acceptance"
                    name="privacy_acceptance" {...register('privacy_acceptance')}>
                    {t('form.help.conditions.label')}
                  </Form.Checkbox>
                  {errors.privacy_acceptance && <Form.Error>{errors.privacy_acceptance?.message}</Form.Error>}
                </Form.Control>
              </Form.Field>
            </Col>
          </Row>

          {errors.apiError &&
            <Notice color="red">{errors.apiError?.message}</Notice>
          }

          <Form.Button disabled={loading}>
            {t('form.help.button')}
            {loading && <Form.Loader />}
          </Form.Button>
        </Form>
      </div>
    </CardContent>
  </>;
};

ContactForm.propTypes = {
  formData: PropTypes.any
};

export default ContactForm;
