
import NotFound from 'components/NotFound';

const PageNotFound = () => {
  return (
    <NotFound />
  );
};

export default PageNotFound;
