import { useTranslation } from 'react-i18next';

import i18n from 'translations';

import styles from './styles.module.scss';

const LinksList = () => {
  const { t } = useTranslation();

  const links = t('links');

  if (!i18n.exists('links') || links.length === 0) return '';

  return (
    <ul className={styles.list}>
      {links.map((item, index) => (
        <li className={styles.item} key={index}><a href={item.link} className={styles.link}>{item.text}</a></li>
      ))}
    </ul>
  );
};

export default LinksList;
