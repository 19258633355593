import styles from './styles.module.scss';
import { PropTypes } from 'prop-types';
import { forwardRef } from 'react';

const Checkbox = forwardRef((props, ref) => {
  const { children, error, htmlFor, ...other } = props;
  return (<label className={`${styles.label} ${error ? styles.error : ''}`} htmlFor={htmlFor}>
    <input type="checkbox" className={styles.checkbox} {...other} ref={ref} />
    {children}
  </label>);
});

Checkbox.displayName = 'Checkbox';

Checkbox.propTypes = {
  htmlFor: PropTypes.string,
  children: PropTypes.node,
  error: PropTypes.any
};

export default Checkbox;
