import { PropTypes } from 'prop-types';
import { createContext, useReducer } from 'react';

import initialState from './initialState';
import reducer from './reducer';

export const RegisterContext = createContext();

const { Provider } = RegisterContext;

const RegisterProvider = (props) => {
  const { value } = props;
  const [state, dispatch] = useReducer(reducer, value || initialState);

  return (
    <Provider value={[state, dispatch]}>
      {props.children}
    </Provider>
  );
};

RegisterProvider.propTypes = {
  children: PropTypes.any,
  value: PropTypes.any
};

export default RegisterProvider;
